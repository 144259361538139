import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import ProductAction from 'components/Blocks/Product/Action/ProductAction';
import ProductAvailableStoresCount, {
    getProductAvailabilityEShopTwClass,
    productAvailabilityTwClass,
} from 'components/Blocks/Product/Availability/ProductAvailableStoresCount';
import ProductFlags from 'components/Blocks/Product/Flags/ProductFlags';
import { ProductListTypes } from 'components/Blocks/Product/List/ProductsList';
import ProductPrice from 'components/Blocks/Product/Price/ProductPrice';
import SimpleColorVariants from 'components/Blocks/Product/SimpleColorVariants/SimpleColorVariants';
import Unwatch from 'components/Blocks/Product/Unwatch/Unwatch';
import { ProductWishlistButton } from 'components/Blocks/Product/Wishlist/ProductWishlistButtons';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { useCallback } from 'react';
import { twJoin } from 'tailwind-merge';
import { AvailabilityStatusEnum } from 'types/availability';
import { GtmListNameType } from 'types/gtm';
import { ListedProductType } from 'types/product';
import { onClickProductDetailGtmEventHandler } from 'utils/Gtm/EventHandlers';

type ProductItemProps = {
    onUnwatch?: () => void;
    gtmListName: GtmListNameType;
    listIndex: number;
    product: ListedProductType;
    type: ProductListTypes;
    inArticle?: boolean;
    underline?: boolean;
    shouldRedirectOnClick?: boolean;
    onProductItemClick?: (product: ListedProductType) => void;
    isConfigurator?: boolean;
    className?: string;
    isProductInWishlist: boolean;
    toggleProductInWishlist: () => void;
};

const ProductItem: FC<ProductItemProps> = ({
    onUnwatch,
    gtmListName,
    listIndex,
    product,
    type,
    inArticle,
    underline,
    onProductItemClick,
    shouldRedirectOnClick = true,
    isConfigurator,
    className,
    isProductInWishlist,
    toggleProductInWishlist,
}) => {
    const testIdentifier = 'blocks-product-list-listeditem-' + product.catalogNumber;
    const { url, type: domainType } = useDomainConfig();
    const t = useTypedTranslationFunction();

    const { isUserLoggedIn } = useCurrentUserData();
    const isActionPrice = product.price.priceWithoutVat < product.prices.basic.priceWithoutVat;
    const isElectronicGiftVoucher = product.isElectronicGiftVoucher;

    const onProductDetailRedirectHandler = useCallback(
        async (product: ListedProductType, listName: GtmListNameType, index: number) => {
            await onClickProductDetailGtmEventHandler(product, listName, index, url);
        },
        [url],
    );

    return (
        <div
            className={twJoin(
                'relative flex flex-col rounded-md text-left',
                'hover:no-underline hover:lg:z-above hover:lg:bg-white hover:lg:shadow-grayShadow hover:lg:[&>img]:mix-blend-multiply',
                underline &&
                    'after:pointer-events-none after:absolute after:-left-[6px] after:-right-[6px] after:-bottom-5 after:h-[1px] after:bg-grayLighter after:content-[""] after:lg:-bottom-2',
                inArticle && 'h-auto',
                '[-webkit-transform:translate3d(0,0,0)]', // Safari box-shadow on hover fix
                className,
            )}
            data-testid={testIdentifier}
            onClick={() => onProductItemClick?.(product)}
        >
            <div className="z-above -mt-7 mr-4 flex translate-y-12 justify-end">
                <ProductWishlistButton
                    toggleProductInWishlist={toggleProductInWishlist}
                    isProductInWishlist={isProductInWishlist}
                />
            </div>
            <ExtendedNextLink
                type="product"
                href={product.slug}
                className="relative flex h-full flex-col justify-between no-underline hover:no-underline lg:py-4 lg:px-5 vl:px-6 [&:not(:last-child)]:pb-0"
                onClick={() => onProductDetailRedirectHandler(product, gtmListName, listIndex)}
                shouldRedirectOnClick={shouldRedirectOnClick}
            >
                <>
                    <div>
                        <div className="relative mx-auto mb-1 flex h-[150px] w-full max-w-[160px] items-center justify-center bg-transparent text-[0px] lg:h-[200px]">
                            <Image
                                className="mix-blend-multiply"
                                src={product.image}
                                width="160"
                                height="160"
                                alt={product.fullName}
                            />
                        </div>
                        <SimpleColorVariants colorVariants={product.colorVariants} />
                        <div className="mb-2 flex min-h-[28px] flex-row flex-wrap items-start gap-1">
                            <ProductFlags flags={product.flags} product={product} isProductDetail={false} />
                        </div>

                        <h3 className="mb-4 block overflow-hidden text-body font-normal leading-6 tracking-wider text-black [word-wrap:break-word]">
                            {product.fullName}
                        </h3>
                    </div>
                    {((product.prices.tescoma && isUserLoggedIn === false) || isActionPrice) && domainType !== 'web' && (
                        <div className="mt-auto text-small">
                            {product.prices.tescoma && isUserLoggedIn === false && (
                                <ProductPrice {...product.prices.tescoma} isTescomaPrice />
                            )}
                            {isActionPrice && <ProductPrice {...product.prices.basic} isOldPrice />}
                        </div>
                    )}
                    <div data-id="productBottom">
                        {domainType !== 'web' && (
                            <>
                                <ProductPrice {...product.price} isActionPrice={isActionPrice} />
                                <div className={productAvailabilityTwClass}>
                                    <span
                                        className={getProductAvailabilityEShopTwClass(
                                            product.availabilityStatus === AvailabilityStatusEnum.inStock,
                                        )}
                                    >
                                        {product.availability.status === AvailabilityStatusEnum.inStock
                                            ? t('Available in eshop')
                                            : t('Unavailable in eshop')}
                                    </span>
                                    {!isElectronicGiftVoucher && (
                                        <ProductAvailableStoresCount
                                            isMainVariant={product.isMainVariant}
                                            isInCentralStore={
                                                product.availabilityStatus === AvailabilityStatusEnum.inStock
                                            }
                                            availableStoresCount={product.availableStoresCount}
                                            deliveryDaysFromCentralStore={product.deliveryDaysFromCentralStore}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            </ExtendedNextLink>

            {type === ProductListTypes.Normal && (
                <ProductAction
                    product={product}
                    gtmListName={gtmListName}
                    listIndex={listIndex}
                    isConfigurator={isConfigurator}
                />
            )}
            {type === ProductListTypes.WatchDog && onUnwatch !== undefined && <Unwatch onUnwatch={onUnwatch} />}
        </div>
    );
};

export default ProductItem;
